




































































































































































import Vue from 'vue';
import MyEditor from '@/components/global/myEditor/index.vue';
import { message, Modal } from 'ant-design-vue';
import * as api from '@/api/appShelf';
import { Tag } from '@/interface';
import moment from 'moment';
export default Vue.extend({
  name: 'appShelfDetails',
  components: { MyEditor },
  data() {
    return {
      editFlag: false, //可编辑状态
      tableLoading: false,
      submitLoading: false,
      changeShelfLoading: false,
      detailsData: {} as any,
      specificationsColumns: [
        {
          title: '单位',
          align: 'center',
          dataIndex: 'skuName',
        },
        {
          title: '划线价（元）',
          align: 'center',
          dataIndex: 'originalPrice',
          scopedSlots: { customRender: 'originalPrice' },
        },
        {
          title: '售价（元）',
          align: 'center',
          dataIndex: 'sellingPrice',
          scopedSlots: { customRender: 'sellingPrice' },
        },
      ],
      specificationsData: [] as any,
      dynamicTableHeader: [] as any, //sku 动态表头临时存储
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getAppShelfDetail(this.$route.query.id + '');
    }
  },
  methods: {
    moment,
    createProdStatus(prodStatus: string) {
      if (prodStatus == '1') {
        return '上架中';
      } else if (prodStatus == '2') {
        return '已下架';
      } else if (prodStatus == '3') {
        return '资源已删除';
      } else {
        return '已过期';
      }
    },
    handleEditorData(prodDetailsJson: string) {
      return prodDetailsJson ? JSON.parse(prodDetailsJson) : [];
    },
    createTableHeader(headerArr) {
      //生成动态表头
      const currentArr = headerArr.map((item, index) => {
        return {
          title: item.attrName,
          align: 'center',
          dataIndex: item.attrCode + index,
        };
      });
      this.dynamicTableHeader = currentArr;
      this.specificationsColumns = [
        ...currentArr,
        {
          title: '划线价（元）',
          align: 'center',
          dataIndex: 'originalPrice',
          scopedSlots: { customRender: 'originalPrice' },
        },
        {
          title: '售价（元）',
          align: 'center',
          dataIndex: 'sellingPrice',
          scopedSlots: { customRender: 'sellingPrice' },
        },
      ];
    },
    getAppShelfDetail(id: string) {
      this.tableLoading = true;
      api
        .getAppShelfDetail(id)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.result);
            //生成动态表头
            this.createTableHeader(res.result.attributeInstanceDTOS);
            this.detailsData = res.result;
            // this.specificationsData = res.result.productSkuDTOS;
            this.specificationsData = res.result.productSkuDTOS.map((item) => {
              const names = item.skuName.split('|');
              return {
                ...item,
                ...this.dynamicTableHeader.reduce((prev, cur, index) => {
                  prev[cur.dataIndex] = names[index];
                  return prev;
                }, {}),
                key: item.skuCode,
              };
            });
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleInputChange(value: number, key: string, dataIndex: string) {
      // console.log(value);
      // console.log(key);
      // console.log(dataIndex);
      this.specificationsData = this.specificationsData.map((item) => {
        if (item.key === key) {
          return {
            ...item,
            [dataIndex]: value, //变更 dataIndex 属性
          };
        }
        return item;
      });
    },
    onShelf() {
      //上架
      Modal.confirm({
        title: '确定要进行上架操作吗？',
        onOk: () => {
          this.handleShelfStatus(1);
        },
        onCancel: () => {
          console.log('onShelf-Cancel');
        },
      });
    },
    offShelf() {
      //下架
      Modal.confirm({
        title: '确定要进行下架操作吗？',
        onOk: () => {
          this.handleShelfStatus(2);
        },
        onCancel: () => {
          console.log('offShelf-Cancel');
        },
      });
    },
    handleShelfStatus(status: number) {
      this.changeShelfLoading = true;
      api
        .handleShelfStatus(
          this.detailsData.sequenceNbr,
          status,
          this.detailsData.version
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.result) {
              message.success('操作成功！');
              this.closeCurrentTags(
                '/operate/appShelf/details',
                '/operate/appShelf'
              );
              this.$destroy();
            }
          }
        })
        .finally(() => {
          this.changeShelfLoading = false;
        });
    },
    handleEdit() {
      this.editFlag = true;
    },
    handleReturnBack() {
      this.closeCurrentTags('/operate/appShelf/details', '/operate/appShelf');
    },
    handleCancel() {
      this.editFlag = false;
      // this.specificationsData = [...this.detailsData.productSkuDTOS];
      this.specificationsData = this.detailsData.productSkuDTOS.map((item) => {
        const names = item.skuName.split('|');
        return {
          ...item,
          ...this.dynamicTableHeader.reduce((prev, cur, index) => {
            prev[cur.dataIndex] = names[index];
            return prev;
          }, {}),
          key: item.skuCode,
        };
      });
    },
    handleSave() {
      const currentData = {
        sequenceNbr: this.detailsData.sequenceNbr,
        prodDetails: (this.$refs.editor as HTMLFormElement).latestContentHtml,
        prodDetailsJson: (this.$refs.editor as HTMLFormElement)
          .latestContentJson,
        productSkuDTOS: [...this.specificationsData],
      };
      // console.log('handleSave:', currentData);
      for (let item of currentData.productSkuDTOS) {
        if (
          typeof item.originalPrice !== 'number' ||
          typeof item.sellingPrice !== 'number'
        ) {
          message.error('请输入正确的划线价与售价');
          return;
        }
      }
      this.submitLoading = true;
      api
        .editAppShelf(currentData)
        .then((res) => {
          if (res.status === 200) {
            if (res.result) {
              // console.log('主表单保存成功结果');
              // console.log(res);
              message.success('保存成功！');
              this.closeCurrentTags(
                '/operate/appShelf/details',
                '/operate/appShelf'
              );
              this.$destroy();
            }
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    closeCurrentTags(currentPath: string, toPath: string) {
      // 关闭当前tag,并跳转到指定页面
      const filterTags = this.$store.state.common.tags.filter(
        (tag: Tag) => tag.path !== currentPath
      );
      this.$store.commit('SET_TAGS', filterTags);
      this.$router.push({ path: toPath });
    },
  },
});
